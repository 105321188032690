import styled from 'styled-components';

const HomeStyled = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default HomeStyled;